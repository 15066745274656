
import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import {InternalPersonalYearEndSummaryDto, AuditFlowScope} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "internalPersonalYearEndSummaryList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag
  },
  filters: {
    formatDate(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    }
  }
})
export default class internalPersonalYearEndSummaryList extends Vue {
  queryForm: any = {
    year: undefined,
    userName: ""
  };

  public value1: Date = new Date();
  public pickerOptions: any = {
    disabledDate: (time: any) => {
      const minYear = new Date().getFullYear() - 10;
      return (
        time > Date.now() || time.getTime() < new Date(JSON.stringify(minYear))
      );
    }
  };

  fetchData(params: any) {
    return api.internalPersonalYearEndSummary.getAll(params);
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "internalPersonalYearEndSummaryCreate"
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "internalPersonalYearEndSummaryEdit",
      params: {
        id: row.id
      }
    });
  }

  async handleStartAudit(
    index: number,
    row: InternalPersonalYearEndSummaryDto
  ) {
    await api.internalPersonalYearEndSummary
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.InternalPersonalYearEndSummary,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "internalPersonalYearEndSummaryDetail",
      params: {
        id: row.id
      }
    });
  }

  // 删除
  async handleDelete(index: number, row: InternalPersonalYearEndSummaryDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.internalPersonalYearEndSummary
        .delete({id: row.id})
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        });
    });
  }
}
