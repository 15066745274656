
import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import {
  InternalPersonalYearEndSummaryDto,
  OrganizationUnitDto,
  AttachmentHostType,
  AuditFlowScope
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "InternalPersonalYearEndSummaryDetail",
  components: {
    AttachmentsView,
    AuditButtons,
    ExportWord
  }
})
export default class InternalPersonalYearEndSummaryDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: InternalPersonalYearEndSummaryDto = {
    year: undefined,
    role: undefined,
    userName: undefined,
    summary: undefined,
    id: 0
  };
  hostType = AttachmentHostType.Internal_Personal_Year_End_Summary;
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.InternalPersonalYearEndSummary,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };
  selector: string = "table-detail";
  filename: string = "个人年终总结";
  styles: string = "";

  fetchAuditData() {
    return api.internalPersonalYearEndSummary.audit;
  }

  changeAudit() {
    this.fetchDetail();
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.internalPersonalYearEndSummary
      .get({ id: this.dataId })
      .then(res => {
        this.detail = { ...res };
        this.auditParams!.hostId = res.id + "";
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit();
        }
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
